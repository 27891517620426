import { PageShell } from "../components/PageShell";
import { Post } from "../components/Post";
import { TITLE } from "./constants";
import { PageContext, PostMeta } from "./types";

export const consumeContext = (pageContext: PageContext) => {
  const { Page, urlPathname } = pageContext;
  const isPost = urlPathname.startsWith("/post/");

  if (!isPost) {
    const getPage = () => (
      <PageShell pageContext={pageContext}>
        <Page />
      </PageShell>
    );
    return { getPage, getPageTitle: () => TITLE, isPost };
  }

  const getPage = ({
    prevMeta,
    meta,
    nextMeta,
  }: {
    prevMeta?: PostMeta;
    meta?: PostMeta;
    nextMeta?: PostMeta;
  }) => (
    <PageShell pageContext={pageContext}>
      <Post prevMeta={prevMeta} meta={meta} nextMeta={nextMeta}>
        <Page />
      </Post>
    </PageShell>
  );

  const getPageTitle = (meta?: PostMeta) =>
    meta?.title ? `${meta.title} | ${TITLE}` : `${TITLE}`;

  return { getPage, getPageTitle, isPost };
};
