import { useColorModeValue } from "@chakra-ui/react";

export const useColors = () => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const checkboxBackground = useColorModeValue("orange.600", "orange.300");
  const checkboxBackgroundHover = useColorModeValue("orange.700", "orange.400");
  const footnoteBackground = useColorModeValue("gray.100", "gray.700");
  const footnoteBackgroundHover = useColorModeValue("gray.200", "gray.600");
  const linkColor = useColorModeValue("orange.800", "orange.200");
  const linkBackground = useColorModeValue("orange.100", "orange.900");
  const subtitleColor = useColorModeValue("gray.500", "whiteAlpha.700");
  const textColor = useColorModeValue("gray.900", "whiteAlpha.900");

  return {
    borderColor,
    checkboxBackground,
    checkboxBackgroundHover,
    footnoteBackground,
    footnoteBackgroundHover,
    linkColor,
    linkBackground,
    subtitleColor,
    textColor,
  };
};
