import {
  Box,
  BoxProps,
  LinkProps,
  ListItem,
  OrderedList,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  TextProps,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createContext,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Markdown } from "./Markdown";
import { Link } from "./Link";
import { useColors } from "./useColors";
import { Text } from "./Text";

type Footnote = {
  content: string;
  label?: string;
};

type FootnoteInfo = {
  label: string;
  index: number;
};

const FootnoteContext = createContext<{
  get: () => Required<Footnote>[];
  push: (footnote: Footnote) => FootnoteInfo;
}>({
  get: () => [],
  push: () => {
    throw new Error("Not in FootnoteProvider");
  },
});

/** TODO: are there heuristics for finding a better label? */
const contentToLabel = (content: string) => {
  return content
    .slice(0, 20)
    .replaceAll(/\s/g, "-")
    .replaceAll(/[^\w-]/g, "")
    .slice(0, 10)
    .toLowerCase();
};

export const FootnoteProvider = ({ children }: { children: ReactNode }) => {
  const mounted = useRef(false);
  const footnotes = useRef<Required<Footnote>[]>([]);

  if (!mounted.current) {
    mounted.current = true;
    footnotes.current = [];
  }

  return (
    <FootnoteContext.Provider
      value={{
        get: () => footnotes.current,
        push: (footnote) => {
          const label = footnote.label ?? contentToLabel(footnote.content);

          const existingIndex = footnotes.current.findIndex(
            (f) => f.label === label,
          );
          if (existingIndex !== -1) {
            return { label, index: existingIndex + 1 };
          }

          const index = footnotes.current.push({ label, ...footnote });

          return { label, index };
        },
      }}
    >
      {children}
    </FootnoteContext.Provider>
  );
};

const FootnoteBox = forwardRef<HTMLSpanElement, BoxProps>(function FootnoteBox(
  { children, sx, ...props },
  ref,
) {
  const { footnoteBackground, linkBackground } = useColors();

  return (
    <Box
      as="span"
      bg={footnoteBackground}
      cursor="help"
      minWidth={6}
      pl={0.5}
      sx={{
        "&:hover": { bg: linkBackground },
        "& a:hover": { textDecoration: "underline" },
        ...sx,
      }}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  );
});

const FootnoteNumber = forwardRef<HTMLAnchorElement, FootnoteInfo & LinkProps>(
  function FootnoteNumber({ label, index, ...props }, ref) {
    return (
      <Link
        display="inline-block"
        icon=""
        href={`#fn-${label}`}
        aria-describedby="footnotes"
        role="doc-noteref"
        px={0.5}
        minWidth={4}
        ref={ref}
        userSelect="none"
        sx={{}}
        {...props}
      >
        <Text as="sup" textStyle="sans">
          {index}
        </Text>
      </Link>
    );
  },
);

const FootnoteDesktop = ({
  children,
  content,
  label,
  index,
  ...props
}: PropsWithChildren<Footnote & FootnoteInfo> & BoxProps) => {
  return (
    <Popover
      placement="top"
      trigger="hover"
      modifiers={[{ name: "preventOverflow", options: { padding: 16 } }]}
    >
      <PopoverTrigger>
        <FootnoteBox {...props}>
          {children}
          <PopoverAnchor>
            <FootnoteNumber label={label} index={index} />
          </PopoverAnchor>
        </FootnoteBox>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow as="span" />
          <PopoverBody p={2}>
            <Box fontSize="sm">
              <Markdown content={content} inline={true} />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const Bracket = ({ children, ...props }: TextProps) => {
  const { linkColor } = useColors();

  return (
    <Text as="span" color={linkColor} textStyle="sans" {...props}>
      {children}
    </Text>
  );
};

const FootnoteMobile = ({
  children,
  content,
  label,
  index,
  ...props
}: PropsWithChildren<Footnote & FootnoteInfo> & BoxProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <FootnoteBox onClick={onToggle} {...props}>
      {children}
      <FootnoteNumber
        label={label}
        index={index}
        minWidth={isOpen ? 0 : 4}
        onClick={(e) => {
          e.preventDefault();
        }}
        ml={0.5}
        pl={0}
        pr={0.5}
      />
      {isOpen && (
        <>
          <Bracket pl={0.5}>[</Bracket>
          <Markdown content={content} inline={true} />
          <Bracket pr={0.5}>]</Bracket>
        </>
      )}
    </FootnoteBox>
  );
};

export const Footnote = (props: PropsWithChildren<Footnote>) => {
  const [isMounted, setIsMounted] = useState(false);
  const { push } = useContext(FootnoteContext);
  const { label, index } = push(props);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const isDesktop = isMounted && window.matchMedia("(hover: hover)").matches;

  return (
    <>
      <a id={`fnref-${label}`} />
      {isDesktop ? (
        <FootnoteDesktop {...props} label={label} index={index} />
      ) : (
        <FootnoteMobile {...props} label={label} index={index} />
      )}
    </>
  );
};

export const Footnotes = () => {
  const { get } = useContext(FootnoteContext);

  return (
    <OrderedList
      id="footnotes"
      role="doc-footnote"
      display="grid"
      gridTemplateColumns="max-content 1fr"
      alignItems="stretch"
      columnGap={2}
      m={0}
      mt={10}
      borderTopWidth="3px"
      pt="parSpace"
    >
      {get().map(({ label, content }, index) => (
        <ListItem display="contents" listStyleType="none" key={label}>
          <Link
            aria-label={`Return to reference ${index + 1}`}
            role="doc-backlink"
            icon=""
            href={`#fnref-${label}`}
            id={`fn-${label}`}
            textAlign="right"
            minWidth={4}
          >
            <Text as="sup" textStyle="sans">
              {index + 1}
            </Text>
          </Link>
          <Box
            sx={{
              "& > *:first-child": { mt: 0 },
              "& > *:last-child": { mb: 2 },
            }}
          >
            <Markdown content={content} />
          </Box>
        </ListItem>
      ))}
    </OrderedList>
  );
};
